import styled from 'styled-components'

import { Sizes } from '../Config'

const EarthGlow = styled.div`
  height: 200px;
  width: 100%;
  background:
    linear-gradient(
      to top,
      rgba(0, 134, 210, 1) 0%,
      rgba(0, 134, 210, 1) 10%,
      transparent 100%
     );
  background:
    radial-gradient(
      100% 100% at bottom,
      transparent 60%,
      rgba(0, 134, 210, 1) 60.5%,
      rgba(0, 134, 210, .4) 61.5%,
      transparent
     );

  @media (max-width: ${Sizes.m}) {
    height: 100px;
  }
`;

export default EarthGlow;