import React from 'react'
import styled from 'styled-components'

import FaceBookImg from '../assets/icons/facebook.png'
import TwitterImg from '../assets/icons/twitter.png'
import InstagramImg from '../assets/icons/instagram.png'
import LinkedInImg from '../assets/icons/linkedin.png'
import YouTubeImg from '../assets/icons/youtube.png'
import GitHubImg from '../assets/icons/github.png'
import RedditImg from '../assets/icons/reddit.png'
import FlickrImg from '../assets/icons/flickr.png'
import { Links } from '../Config'

const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 3em;
`;

const Img = styled.img`
  width: 2.5em;
  height: 2.5em;
  padding: 0 1em;
`;

export default () => (
  <SocialLinksContainer>
    <a href={Links.facebook}><Img src={FaceBookImg} /></a>
    <a href={Links.twitter}><Img src={TwitterImg} /></a>
    <a href={Links.instagram}><Img src={InstagramImg} /></a>
    <a href={Links.linkedin}><Img src={LinkedInImg} /></a>
    <a href={Links.youtube}><Img src={YouTubeImg} /></a>
    <a href={Links.github}><Img src={GitHubImg} /></a>
    <a href={Links.reddit}><Img src={RedditImg} /></a>
    <a href={Links.flickr}><Img src={FlickrImg} /></a>
  </SocialLinksContainer>
)
