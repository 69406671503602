import React from 'react'

import SectionTitle from './SectionTitle'
import Slider from './Slider'
import D1Img from '../assets/projects/d1.jpg'
import D2Img from '../assets/projects/d2.jpg'
import D3Img from '../assets/projects/d3.jpg'
import D4Img from '../assets/projects/d4.jpg'
import D5Img from '../assets/projects/d5.jpg'
import D6Img from '../assets/projects/d6.jpg'
import D7Img from '../assets/projects/d7.jpg'
import D8Img from '../assets/projects/d8.jpg'
import D9Img from '../assets/projects/d9.jpg'
import D10Img from '../assets/projects/d10.jpg'
import D11Img from '../assets/projects/d11.jpg'
import D12Img from '../assets/projects/d12.jpg'
import M1Img from '../assets/projects/m1.jpg'
import M2Img from '../assets/projects/m2.jpg'
import M3Img from '../assets/projects/m3.jpg'
import M4Img from '../assets/projects/m4.jpg'
import M5Img from '../assets/projects/m5.jpg'
import M6Img from '../assets/projects/m6.jpg'
import M7Img from '../assets/projects/m7.jpg'
import M8Img from '../assets/projects/m8.jpg'
import M9Img from '../assets/projects/m9.jpg'
import M10Img from '../assets/projects/m10.jpg'
import M11Img from '../assets/projects/m11.jpg'
import M12Img from '../assets/projects/m12.jpg'
import { BoldFont } from '../Helpers'

const data = [
  {
    imageD: D1Img,
    imageM: M1Img,
    title: 'W O L F I V E R S E',
    lines: [
      'Interactive Story Game',
      'Multiverse',
      'Quantum Randomness',
      'Folk Tales',
    ],
    team: 'Aurélien - Diogo - Junye - Omar - Sameed - Yishan'
  },
  {
    imageD: D2Img,
    imageM: M2Img,
    title: 'Quanutm Music',
    lines: [
      'Quantum Musical Piano',
    ],
    team: 'Nida Ajmi, Max Hunter Gordon, Artem Kislovskiy, Nicole Morgan, Soumya Shreeram, Manuel Morgado Vargas'
  },
  {
    imageD: D3Img,
    imageM: M3Img,
    title: 'Quantum Quest',
    lines: [
      'VR Gamification of Quantum Physics through the “eyes” of particles',
      'the perception of nature v.s. measurement'
    ],
    team: 'Amba Sayal-Bennett, Amber Borowiec, Marc Gansler, Evita Gamber, Charlotte Qin,  Kwok ho wan'
  },
  {
    imageD: D4Img,
    imageM: M4Img,
    title: 'q b p : Quantum Puzzlers',
    lines: [
      'Quantum Technologies',
      'Superposition',
      'Serious video game',
    ],
    team: 'Adrián Navarro Pérez, Christopher William Black, Eleni Vatamidou, Ludvig Arlebrink, Marek Černák, Mohamed Asif Iqbal Mohamed Haneefa, Samuel Soutullo Sobral'
  },
  {
    imageD: D5Img,
    imageM: M5Img,
    title: 'Quantum Enabled Provision of Global Public Goods',
    lines: [
      'Use quantum technologies to meet the SDGs.',
    ],
    team: 'Ana Torres, Arthur Strauss, Elijah Ko, Fern Pannell, Kia Kouyoumjian, Laura Dudek, Zeeshan Siddique'
  },
  {
    imageD: D6Img,
    imageM: M6Img,
    title: 'QCTx|Bank',
    lines: [
      'Educational and fun way to interact with quantum errors and get a',
      'sense of hurdles to create a real quantum device',
    ],
    team: 'Markus C Braun, Alexandra Pinto, Hakim Elakhrass, Jean Philippe, Joel Lauener, Arias Zapata, Steven Lindgren'
  },
  {
    imageD: D7Img,
    imageM: M7Img,
    title: 'QMLEC: Quantum Machine Learning Error correction',
    lines: [
      'A novel approach to Quantum Error Correction',
    ],
    team: 'Illia Babounikau, Sadra Boreiri, David Ittah, Jake Malliaros, Dmitry Grinko, Anton Karazeev'
  },
  {
    imageD: D8Img,
    imageM: M8Img,
    title: 'Quantum Democratica',
    lines: [
      'Should we invest in Mars mission',
    ],
    team: ''
  },
  {
    imageD: D9Img,
    imageM: M9Img,
    title: 'attractive kids',
    lines: [
      'Technology Consciousness',
    ],
    team: ''
  },
  {
    imageD: D10Img,
    imageM: M10Img,
    title: 'Quantum Machine Learning for Quantum Data',
    lines: [
      'Use Machine Learning to Mimic a complicated quantum circuit.',
    ],
    team: 'Semih Korkmaz, Amine Rusi el hassani, Ryu Sawada, Koji Terashi'
  },
  {
    imageD: D11Img,
    imageM: M11Img,
    title: 'Q-Digger',
    lines: [
      'Random walk',
    ],
    team: 'Stephen Diadamo, Sajanth Subramaniam, Aiman Al-Eryani, Kiran Adhikari'
  },
  {
    imageD: D12Img,
    imageM: M12Img,
    title: 'Distributed QuantumComputing',
    lines: [
      'Quantum cloud computing speed-up',
    ],
    team: 'Hakob Avetisyan,  Misha Aghamalyan,  Hayk Sargsyan,  Arshak Hovhannisyan'
  },
]


const Component: React.FC = () => {
  return (
    <>
      <SectionTitle>Emerged Projects</SectionTitle>

      <BoldFont><Slider data={data} /></BoldFont>
    </>
  )
}


export default Component