import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import Avatar from './Avatar'
import Title from './SectionTitle'
import JohnEllisImg from '../assets/persons/JohnEllis.png'
import BenSegalImg from '../assets/persons/BenSegal.png'
import JackHidaryImg from '../assets/persons/JackHidary.png'
import { Sizes } from '../Config'
import { SemiBoldFont } from '../Helpers'

const D = styled.p`
  padding-bottom: 3em;
  margin: 0 2em;
  margin-right: 0;
  @media (max-width: ${Sizes.s}) {
    margin-left: 1em;
  }
`;

const A2D = styled(D)`
  text-align: right;
  margin-left: 0;
  margin-right: 2em;

  @media (max-width: ${Sizes.s}) {
    margin-left: 0;
    margin-right: 1em;
  }

  @media (max-width: ${Sizes.xs}) {
    text-align: center;
  }
`;

const Flex = styled.div`
  display: flex;

  flex-direction: column;

  max-width: 30rem;
  margin: auto;
  align-items: center;
  justify-content: middle;
`;

const Row = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: middle;

  @media (max-width: ${Sizes.xs}) {
    flex-direction: column;
    text-align: center;
  }
`;

const RowI = styled(Row)`
  flex-direction: row-reverse;
`;

const Component: React.FC = () => (
  <Container>
    <Title>Featured Speakers</Title>
    <Flex>
      <Row>
        <Avatar
          src={JohnEllisImg}
          name="John Ellis"
          showMargin={false}
        />
        <SemiBoldFont>
          <D>Clerk Maxwell Professor<br/>of theoretical physics</D>
        </SemiBoldFont>
      </Row>

      <RowI>
        <Avatar
          src={BenSegalImg}
          name="Ben Segal"
          showMargin={false}
        />
        <SemiBoldFont>
          <A2D>Tech Pioneer,<br/>he brought internet to life</A2D>
        </SemiBoldFont>
      </RowI>

      <Row>
        <Avatar
          src={JackHidaryImg}
          name="Jack Hidary"
          showMargin={false}
        />
        <SemiBoldFont>
          <D>AlphabetX-AI & Physics;<br />Hidaru Foundation at X GoogleX</D>
        </SemiBoldFont>
      </Row>
    </Flex>
  </Container>
)

export default Component
