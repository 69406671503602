import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import { useInterval } from '../Helpers'

/*
  Had to do some changes to the dots styles
  so I copied react-swift-slider

  There's a modified copy of this in Slider.tsx as well
*/

const DIRECTION = {
  prev: 'prev',
  next: 'next',
};

const SwiftSliderControlBase = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 10;
  padding: 0 2rem;
  width: 30%;
  height: ${props => props.height}px;
  cursor: pointer;
  align-items: center;
`;

const SwiftSliderControlPrev = styled(SwiftSliderControlBase)`
  left: 0;
  &:hover {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  }
`;

const SwiftSliderControlNext = styled(SwiftSliderControlBase)`
  right: 0;
  &:hover {
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.5));
  }
`;

function Control({
  onPressNext,
  onPressPrev,
  direction,
  height,
}) {
  return direction === DIRECTION.prev ? (
    <SwiftSliderControlPrev onClick={onPressPrev} height={height} />
  ) : (
    <SwiftSliderControlNext onClick={onPressNext} height={height} />
  );
}


const SwiftSliderDot = styled.li`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 20px;
  margin: 0 .2rem;
  cursor: pointer;
  background: ${props => props.background};
`;

function Dot({
  active,
  onClick,
  idx,
  activeDotColor,
  dotColor,
}) {
  return (
    <SwiftSliderDot
      onClick={() => onClick(idx)}
      background={active ? activeDotColor : dotColor}
    />
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const SwiftSliderSlide = styled.li`
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => (props.active ? '2' : '0')};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  transition: visibility 0.3s;
  animation: ${props => (props.active ? fadeIn : fadeOut)} 0.8s;
  background-image: url('${props => props.backgroundImage}');
  will-change: opacity;
  will-change: visibility;
  will-change: z-index;
`;

function Slide({ src, active }) {
  return <SwiftSliderSlide backgroundImage={src} active={active} />;
}

const SwiftSliderContainer = styled.div`
  position: relative;
  height: ${props => props.height || '450'}px;
  @media (max-width: 600px) {
    height: 250px;
  }
`;

const SwiftSliderSlides = styled.ul`
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
  height: ${props => props.height || '450'}px;
  @media (max-width: 600px) {
    height: 250px;
  }
`;

const SwiftSliderDots = styled.ul`
  list-style: none;
  margin: 1.7rem 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 600px) {
    margin: 1rem 0;
  }
`;

function ReactSlider({
  data,
  height,
  activeDotColor,
  interval,
  dotColor,
  showDots,
  enableNextAndPrev,
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentInterval, setCurrentInterval] = useState(interval);

  useInterval(() => {
    nextSlide();
  }, currentInterval);

  useEffect(() => {
    data.forEach(img => {
      const image = new Image();
      image.src = img.src;
    });
  }, [data]);

  const nextSlide = () => {
    if (currentSlide === data.length - 1) {
      return setCurrentSlide(0);
    }
    setCurrentSlide(currentSlide + 1);
  };

  const prevSlide = () => {
    if (currentSlide === 0) {
      return setCurrentSlide(data.length - 1);
    }
    setCurrentSlide(currentSlide - 1);
    setCurrentInterval(currentInterval + 1);
  };

  const goToSlide = idx => {
    setCurrentSlide(idx);
    setCurrentInterval(currentInterval - 1);
  };

  return (
    <SwiftSliderContainer>
      <SwiftSliderSlides height={height}>
        {data.map((item, i) => (
          <Slide active={i === currentSlide} src={item.src} key={item.id} />
        ))}
      </SwiftSliderSlides>
      {showDots ? (
        <SwiftSliderDots>
          {data.map((item, i) => (
            <Dot
              activeDotColor={activeDotColor}
              dotColor={dotColor}
              key={i}
              active={i === currentSlide}
              onClick={goToSlide}
              idx={i}
            />
          ))}
        </SwiftSliderDots>
      ) : (
        ''
      )}
      {enableNextAndPrev ? (
        <Control
          onPressPrev={prevSlide}
          direction={DIRECTION.prev}
          height={height}
        />
      ) : (
        ''
      )}
      {enableNextAndPrev ? (
        <Control
          onPressNext={nextSlide}
          direction={DIRECTION.next}
          height={height}
        />
      ) : (
        ''
      )}
    </SwiftSliderContainer>
  );
}

ReactSlider.defaultProps = {
  data: [],
  height: 450,
  activeDotColor: '#e8784e',
  interval: 5000,
  dotColor: '#909192',
  showDots: true,
  enableNextAndPrev: true,
};

export default ReactSlider;
