import React, { useRef } from 'react'
import styled from 'styled-components'

import Container from './Container'
import locationImg from '../assets/icons/location.png'
import calendarImg from '../assets/icons/calendar.png'
import earthImg from '../assets/icons/earth.png'
import topImg from '../assets/icons/top.png'
import industryImg from '../assets/icons/industry.png'
import nextImg from '../assets/icons/next.png'
import { Colors, Sizes } from '../Config'
import { BoldFont, BolderFont, SemiBoldFont, MediumFont, NormalFont, useResize } from '../Helpers'

const Icon = styled.img`
  height: auto;
  width: 1.3rem;
`;

const Seperator = styled.span`
  width: 1px;
  height: 2.3rem;
  background: white;
  grid-area: seperator;
  margin: 0 5em;
  margin-top: auto;

  @media (max-width: ${Sizes.m}) {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 0;
    margin-bottom: 0;
    align-self: self-end;
  }
`;

const LocationI = styled(Icon)`
  grid-area: locationI;
`;

const CalendarI = styled(Icon)`
  grid-area: calendarI;
`;

const LocationT  = styled.span`
  grid-area: locationT;
`;

const CalendarT  = styled.span`
  grid-area: calendarT;
`;

const DateAndPlaceConainer = styled.div`
  display: grid;

  grid-template-areas:
    "locationI seperator calendarI"
    "locationT seperator calendarT"
  ;

  text-align: center;
  justify-items: center;
  align-items: center;
`;

const DateAndPlace = () => (
  <DateAndPlaceConainer>
    <LocationI src={locationImg} />
    <LocationT>IdeaSquare, CERN</LocationT>

    <Seperator></Seperator>

    <CalendarI src={calendarImg} />
    <CalendarT>October 18-20, 2019</CalendarT>
  </DateAndPlaceConainer>
);

const TopAndIndustryConainer = styled.div`
  display: grid;

  grid-template-areas:
    "topI . industryI"
    "topT and industryT"
  ;

  grid-template-columns: 1fr auto 1fr;

  justify-items: center;
  align-items: center;

  @media (max-width: ${Sizes.m}) {
    grid-template-areas:
      "topI"
      "topT"
      "and"
      "industryI"
      "industryT"
    ;

    grid-template-columns: 1fr;

  }
`;

const TopI = styled(Icon)`
  grid-area: topI;
  width: 2rem;
`;

const IndustryI = styled(Icon)`
  grid-area: industryI;
  width: 2rem;
`;

const TColored = styled.span`
  color: ${Colors.pink100};
  vertical-align: middle;
`;

const TColoredM = styled(TColored)`
  margin: 1.5em 0;
  font-size: 1.5rem;

  @media (max-width: ${Sizes.m}) {
    margin: .5em 0;
  }
`;

const TopT = styled(TColoredM)`
  grid-area: topT;
`;

const IndustryT = styled(TColoredM)`
  grid-area: industryT;
`;

const And = styled.div`
  font-size: 1.5rem;
  margin: 0 2em;
  grid-area: and;

  @media (max-width: ${Sizes.m}) {
    margin-bottom: .5em;
  }
`;

const TopAndIndustry = () => (
  <TopAndIndustryConainer>
    <TopI src={topImg} />
    <TopT><BolderFont color={Colors.pink100}>TOP SCIENTISTS</BolderFont></TopT>

    <And>&</And>

    <IndustryI src={industryImg} />
    <IndustryT><BolderFont color={Colors.pink100}>INDUSTRY EXPERTS</BolderFont></IndustryT>
  </TopAndIndustryConainer>
);


const ContainerS = styled(Container)`
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const EarthI = styled(Icon)`
  margin: 1em;
  width: 2rem;
  margin-top: 2em;
  margin-bottom: .3em;
`;

const IconText = styled.div`
  text-align: center;
`;

const IconTextB = styled(IconText)`
`;


const IconTextPart = styled.span`
  display: inline-block;
`;

const IconTextBW = styled(IconTextB)`
  font-size: 1.6rem;
  font-width: bold;
  margin: .3em 0;
`;

const IconTextBWM = styled(IconTextBW)`
  margin: .5em 0;
`;

const NextIcon = styled(Icon)`
  vertical-align: middle;
  width: 1rem;
  @media (max-width: ${Sizes.m}) {
    display: block;
    margin: auto;
    margin-top: .5em;
    width: 1.5rem;
  }
`;

const Hackathon = styled.span`
  @media (max-width: ${Sizes.m}) {
    display: block;
    text-align: center;
  }
`;

const SmallD = styled.span`
  font-size: 1rem;
`;

const CheckOut = styled.div`
  margin: 3em 0;
`;

const IconTextOffer = styled(IconText)`
  font-size: 1.2rem;
`;

const Component: React.FC = () => {
  const TopAndIndustryRef = useRef<HTMLHeadingElement>(null);
  const { width, height } = useResize(TopAndIndustryRef)

  return (
    <ContainerS>
      <MediumFont><DateAndPlace /></MediumFont>

      <EarthI src={earthImg} />

      <BoldFont>
        <IconTextBW><BolderFont><SmallD>A UNIQUE OPPORTUNITY TO</SmallD><br/>BE MENTORED BY</BolderFont></IconTextBW>

        <div ref={TopAndIndustryRef}>
          <TopAndIndustry />
        </div>

        <IconTextB>TO LEARN ABOUT QUANTUM TECHNOLOGIES</IconTextB>

        <IconTextBWM><BolderFont>Quantum FutureX <Hackathon>Hackathon</Hackathon></BolderFont></IconTextBWM>
      </BoldFont>

      <NormalFont style={{width: width + 'px'}}>
        <IconTextOffer>Offer participants the chance to delve further into inspiring challenges and apply their skills across disciplines in collaboratives teams.</IconTextOffer>
      </NormalFont>

      <SemiBoldFont>
        <CheckOut><IconText>Check our upcoming <TColored>2020 HACKATHON</TColored> <NextIcon src={nextImg} /></IconText></CheckOut>
      </SemiBoldFont>
    </ContainerS>
  )
}


export default Component