import React from 'react'
import styled from 'styled-components'

import { Sizes } from '../Config'

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

@media (min-width: ${Sizes.s}) {
  max-width: 540px;
}

@media (min-width: ${Sizes.m}) {
  max-width: 720px;
}

@media (min-width: ${Sizes.l}) {
  max-width: 960px;
}

@media (min-width: ${Sizes.xl}) {
  max-width: 1140px;
}
`

const Component: React.FC<{children: React.ReactNode, className?: string}> = ({ children, className }) => (
  <Container className={className}>{children}</Container>
)

export default Component