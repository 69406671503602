import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import EarthGlow from './EarthGlow'
import { Colors, Sizes, SizesI } from '../Config'
import { useWindowDimensions } from '../Helpers'
import octopusWithProfilesImg from '../assets/octopusWithProfiles.png'
import octopusImg from '../assets/octopus.png'

const Title = styled.div`
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: .1rem;
  margin-bottom: 2em;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

const Octopus = styled.img`
`;

const First = styled.span`
  color: ${Colors.pink100}
`;

const X = styled.span`
  font-size: 2.2rem;
`;

const Hackathon = styled.div`
  display: inline-block;

  @media (max-width: ${Sizes.m}) {
    display: block;
    text-align: center;

    // letter-spacing: 3vw;
  }
`;

const OctopusContainer = styled(Container)`
  max-width: 45rem;
  padding: 0 50px;
  @media (max-width: ${Sizes.s}) {
    margin: auto;
  }
`;

const Component: React.FC = () => {

  const { height, width } = useWindowDimensions();

  let image : string;

  if (width <= SizesI.m) {
    image = octopusImg;
  } else {
    image = octopusWithProfilesImg;
  }

  return (
    <>
      <Title>THE <First>1ST</First> QUANTUM FUTURE<X>X</X> <Hackathon>HACKATHON</Hackathon>{width > SizesI.m && <br />}At IdeaSquare, CERN</Title>

      <OctopusContainer>
        <Octopus src={image} />
      </OctopusContainer>

      <EarthGlow />
    </>
  )
}

export default Component