import React from 'react'
import styled from 'styled-components'
import Scroll from 'react-scroll'

import logoImg from '../assets/logo.png'
import fbImg from '../assets/icons/facebook.png'
import inImg from '../assets/icons/linkedin.png'
import redditImg from '../assets/icons/reddit.png'
import { Colors, Sizes, Links } from '../Config'

const Container = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;

  padding: 2em 3em;
  box-sizing: content-box;

  background: linear-gradient(#082536 20%, transparent);

  flex-wrap: wrap;

  @media (max-width: ${Sizes.l}) {
    flex-direction: column;
    padding: 1em 1em;
    padding-bottom: .5em;
  }
`;

const LogoPart = styled.div`
  display: flex;

  align-items: center;

  @media (max-width: ${Sizes.l}) {
    flex-direction: column;
  }
`;

const LinksPart = styled.div`
  display: flex;

  flex-wrap: wrap;

  justify-content: center;
`;

const AdditionalLinksPart = styled.div`
  display: none;

  @media (min-width: ${Sizes.xl}) {
    display: block;
  }
`;

const Logo = styled.img`
  max-width: 5rem;
  vertical-align: middle;
`;

const LogoText = styled.div`
  text-align: left;
  font-weight: 600;

  @media (max-width: ${Sizes.l}) {
    margin: 1em;
    text-align: center;
  }
`;

const Link = styled.span`
  color: inherit;
`;

const LargeSeparator = styled.span`
  font-size: 2rem;
  margin: 0 .2em;

  @media (max-width: ${Sizes.l}) {
    display: none;
  }
`;

const Separator = styled.span`
  color: ${Colors.pink100};
  margin: 0 1em;

  @media (max-width: ${Sizes.l}) {
    margin: 0 .5em;
  }
`;

const Icon = styled.img`
  vertical-align: middle;
  width: 1.5rem;
  margin: 0 .3em;
`;

const NewsletterText = styled.span`
  margin-right: 1em;
`;

const AdditionalLinks = () => (
  <>
    <ScrollLink
      to="footer"
      spy={true}
      smooth={true}
      duration={500}
    >
      <NewsletterText>Newsletter</NewsletterText>
    </ScrollLink>

    <a href={Links.facebook}><Icon src={fbImg} /></a>
    <a href={Links.linkedin}><Icon src={inImg} /></a>
    <a href={Links.reddit}><Icon src={redditImg} /></a>
  </>
)

const ScrollLink = Scroll.Link

const Component: React.FC = () => (
  <Container>
    <LogoPart>
      <Logo src={logoImg} />
      <LargeSeparator>|</LargeSeparator>
      <LogoText>Quantum<br/>FutureX</LogoText>
    </LogoPart>

    <LinksPart>
      <ScrollLink to="about" spy={true} smooth={true} duration={500}>
        <Link>About</Link>
      </ScrollLink>
      <Separator>|</Separator>
      <ScrollLink
        to="projects"
        spy={true}
        smooth={true}
        duration={500}
      >
        <Link>projects</Link>
      </ScrollLink>
      <Separator>|</Separator>
      <ScrollLink
        to="speakers"
        spy={true}
        smooth={true}
        duration={500}
      >
        <Link>Speakers</Link>
      </ScrollLink>
      <Separator>|</Separator>
      <ScrollLink
        to="schedule"
        spy={true}
        smooth={true}
        duration={500}
      >
        <Link>Schedule</Link>
      </ScrollLink>
      <Separator>|</Separator>
      <ScrollLink
        to="team"
        spy={true}
        smooth={true}
        duration={500}
      >
        <Link>Team</Link>
      </ScrollLink>
      <Separator>|</Separator>
      <ScrollLink
        to="partners"
        spy={true}
        smooth={true}
        duration={500}
      >
        <Link>Partners</Link>
      </ScrollLink>
    </LinksPart>

    <AdditionalLinksPart>
      <AdditionalLinks />
    </AdditionalLinksPart>
  </Container>
)

export default Component
