import React from 'react'
import styled from 'styled-components'

import { Colors } from '../Config'
import { SemiBoldFont } from '../Helpers'

const AvatarContainer = styled.div<{showMargin?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: ${props => props.showMargin ? "2em 1em" : "auto"};
`;

const Size = "6em";

const AvatarImg = styled.img<{size?: string}>`
  border-radius: 50%;
  height: ${props => props.size || Size};
  width: auto;
  box-shadow: 0px 0px 2em ${Colors.pink100};
`;

const Name = styled.p`
  color: ${Colors.pink100};
  font-weight: bold;
`;

const Sub = styled.div<{subColor?: string}>`
  color: ${props => props.subColor || "inherit"};
`;

const Head = styled(Sub)`
  font-weight: bold;
  padding-bottom: 2em;
`;

const Component: React.FC<{className?: string, src?: string, name?: string, head?: string, sub1?: string, sub2?: string, sub3?: string, subColor?: string, size?: string, showMargin?: boolean}> = ({className, src, name, head, sub1, sub2, sub3, subColor, size, showMargin=true}) => (
  <AvatarContainer className={className} showMargin={showMargin}>
    {head && <Head>{head}</Head>}
    <AvatarImg src={src} size={size} />
    {name && <Name>{name}</Name>}
    {sub1 && <SemiBoldFont><Sub subColor={subColor}>{sub1 || <br />}<br />{sub2 || <br />}<br />{sub3 || <br />}</Sub></SemiBoldFont>}
  </AvatarContainer>
)

export default Component