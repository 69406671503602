import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import Title from './SectionTitle'
import Supporter1Img from '../assets/supporters/CERNOpenlab.png'
import Supporter2Img from '../assets/supporters/CERNIdea.png'
import Supporter3Img from '../assets/supporters/UDG.png'
import Supporter4Img from '../assets/supporters/CitizenCyberlab.png'
import Supporter5Img from '../assets/supporters/CREATIVEDES.png'
import Supporter6Img from '../assets/supporters/ITU.png'
import Supporter7Img from '../assets/supporters/GoogleX.png'
import Supporter8Img from '../assets/supporters/EPFL.png'
import { Sizes } from '../Config'

const Flex = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  align-content: space-around;
  flex-wrap: wrap;

  @media (max-width: ${Sizes.s}) {
    flex-direction: column;
  }
`;

const Img = styled.img`
  max-width: 15em;
  margin: 2em;
`;

const FlexN = styled(Flex)`
  flex-wrap: nowrap;
`;

const Component: React.FC<{title?: string}> = ({title = 'Partners'}) => (
  <Container>
    <Title>{title}</Title>
    <Flex>
      <FlexN>
        <Img src={Supporter1Img} />
        <Img src={Supporter2Img} style={{maxWidth: '7em'}} />
      </FlexN>
      <FlexN>
        <Img src={Supporter3Img} />
        <Img src={Supporter4Img} />
      </FlexN>
      <FlexN>
        <Img src={Supporter5Img} />
        <Img src={Supporter6Img} style={{maxWidth: '7em'}}  />
      </FlexN>
      <FlexN>
        <Img src={Supporter7Img} style={{maxWidth: '7em'}}  />
        <Img src={Supporter8Img} style={{maxWidth: '10em'}}  />
      </FlexN>
    </Flex>
  </Container>
)

export default Component