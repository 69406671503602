import React from 'react'
import styled from 'styled-components'

const Space = styled.div<{height?: number}>`
  height: ${props => props.height || 25}rem;
`;

const Component: React.FC<{size?: number}> = ({size}) => (
  <Space height={size}></Space>
)

export default Component