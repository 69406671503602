import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import Avatar from './Avatar'
import Title from './SectionTitle'
import JacobShersonImg from '../assets/persons/JacobSherson.png'
import MarkusBraunImg from '../assets/persons/MarkusBraun.png'
import CharelenPutneyImg from '../assets/persons/CharelenPutney.png'
import JamesWoottonImg from '../assets/persons/JamesWootton.png'
import { Sizes } from '../Config'

const Flex = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${Sizes.xs}) {
    flex-direction: column;
  }
`;

const Component: React.FC = () => (
  <Container>
    <Title>Speaking & Coaching</Title>
    <Flex>
      <div>
        <Avatar
          src={JacobShersonImg}
          name="Jacob Sherson"
          sub1="Prof. of Quantum Physics"
          sub2="& director & Founder"
          sub3="of Scienece at Home"
        />
        <Avatar
          src={MarkusBraunImg}
          name="Markus Braun"
          sub1="Founder & Managing director"
          sub2="Director at JoS QUANTUM"
        />
      </div>
      <div>
        <Avatar
          src={CharelenPutneyImg}
          name="Charelene Putney"
          sub1="Writer, Lecturer, Yoga"
          sub2="Teacher (Game Writer)"
        />
        <Avatar
          src={JamesWoottonImg}
          name="James Wootton"
          sub1="Research Staff Member"
          sub2="Scienece & Technology"
          sub3="at IBM Research"
        />
      </div>
    </Flex>
  </Container>
)

export default Component