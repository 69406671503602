import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useInterval } from '../Helpers';

import { Colors, Sizes, SizesI } from '../Config'
import { useWindowDimensions } from '../Helpers'

const TIME = 3000;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const SliderSlide = styled.li<{backgroundImage: string, active: boolean}>`
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => (props.active ? '2' : '0')};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  transition: visibility 0.3s;
  animation: ${props => (props.active ? fadeIn : fadeOut)} 0.8s;
  background-image: url('${props => props.backgroundImage}');
  will-change: opacity;
  will-change: visibility;
  will-change: z-index;
`;

const Slide: React.FC<{src: string, active: boolean}> = ({ src, active }) => {
  return <SliderSlide backgroundImage={src} active={active} />;
}

const DotS = styled.li<{background: string, small: boolean}>`
  width: ${props => props.small ? '.3rem' : '.4rem'};
  height: ${props => props.small ? '.3rem' : '.4rem'};
  border-radius: 20px;
  margin: 0 .1rem;
  opacity: ${props => props.small ? .5 : 1};
  background: ${props => props.background};
`;

const Dot: React.FC<{active: boolean, small?: boolean, }> = ({
  active,
  small = false,
}) => {
  return (
    <DotS
      background={active ? Colors.pink100 : 'white'}
      small={small}
    />
  );
}

const Container = styled.div<{height?: string}>`
  position: relative;
  height: ${props => props.height || '450'}px;
`;

const Slides = styled.ul<{height?: string}>`
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const Project = styled.div<{active: boolean}>`
  text-align: center;
  position: relative;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => (props.active ? '2' : '0')};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  transition: visibility 0.3s;
  animation: ${props => (props.active ? fadeIn : fadeOut)} 0.8s;
  will-change: opacity;
  will-change: visibility;
  will-change: z-index;


  @media (max-width: ${Sizes.l}) {
    top: -30%;
  }

  @media (max-width: ${Sizes.m}) {
    top: -20%;
  }

  @media (max-width: ${Sizes.m}) {
    top: 10%;
  }
`;


// const Position = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 55%;
//   text-align: left;
//   font-weight: bold;

//   @media (max-width: ${Sizes.l}) {
//     position: initial;
//     left: initial;
//     top: initial;
//     transform: initial;
//   }
// `;

const Position = styled.div`
  margin: 1em 0;
`;

const Title = styled(Position)`
  color: ${Colors.pink100};
  font-size: 1.5rem;
`;

const Lines = styled(Position)`
`;

const Team = styled(Position)`
  color: ${Colors.pink100};
`;

const MetaHeight = '17rem';

const Dots = styled(Position)`
  margin: 1.7rem 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5rem;

  justify-content: center;

  position: absolute;
  top: calc(${MetaHeight} + 10rem);
  left: 56vw;

  // z-index 2 in the parent <Project />
  z-index: 3;

  @media (max-width: ${Sizes.l}) {
    top: calc(50% + ${MetaHeight} + 2rem);
    left: calc(50% - 5rem);
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
`;


const Img = styled.img`
  order: 99;

  mask-image: linear-gradient(to right, rgba(0,0,0,1) 10%, rgba(0,0,0,0));

  @media (max-width: ${Sizes.l}) {
    mask-image: linear-gradient(to top, rgba(0,0,0,1) 10%, rgba(0,0,0,0));
  }

  @media (max-width: ${Sizes.s}) {
    padding-top: 10em;
  }
`;

const MetaData = styled.div`
  position: absolute;
  top: calc(${MetaHeight} - 10rem);
  left: 53%;
  max-width: 25rem;
  text-align: left;
  font-weight: bold;

  @media (max-width: ${Sizes.l}) {
    top: calc(${MetaHeight} / 2);
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  @media (max-width: ${Sizes.m}) {
    top: calc(${MetaHeight} / 3);
  }
`;

const MetaMetaData = styled.div`
  height: ${MetaHeight};
`;

type Data =  {
  imageD: string,
  imageM: string,
  title: string,
  lines: string[],
  team: string
}

const ReactSlider : React.FC<{data: Data[], activeDotColor?: string, dotColor?: string}> = ({
  data,
  activeDotColor,
  dotColor,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentInterval, setCurrentInterval] = useState(TIME);

  useInterval(() => {
    nextSlide();
  }, currentInterval);

  useEffect(() => {
    data.forEach(img => {
      const image = new Image();
      image.src = img.imageD;
    });
  }, [data]);

  const nextSlide = () => {
    if (currentSlide === data.length - 1) {
      return setCurrentSlide(0);
    }
    setCurrentSlide(currentSlide + 1);
  };

  const prevSlide = () => {
    if (currentSlide === 0) {
      return setCurrentSlide(data.length - 1);
    }
    setCurrentSlide(currentSlide - 1);
    setCurrentInterval(currentInterval + 1);
  };

  // @ts-ignore
  const goToSlide = idx => {
    setCurrentSlide(idx);
    setCurrentInterval(currentInterval - 1);
  };

  const { height, width } = useWindowDimensions();

  let isMobile = width <= SizesI.l;

  return (
    <Container>
      <Slides>
        {data.map((item, i) => (
          <Project active={i === currentSlide} key={i}>
            <Img src={isMobile ? item.imageM : item.imageD} />
            <MetaData>
              <MetaMetaData>
                <Title>{item.title}</Title>
                <Lines>{item.lines.map((line) =><>{line} <br /></>)}</Lines>
                <Team><span>TEAM {i + 1}</span>{item.team && <span>:<br />{item.team}</span>}</Team>
              </MetaMetaData>
            </MetaData>
          </Project>
        ))}
        <Dots>
            {data.map((item, i) => (
              <Dot
                key={i}
                active={i === currentSlide}
              />
            ))}
        </Dots>
      </Slides>
    </Container>
  );
}

export default ReactSlider;
