import React from 'react'
import styled from 'styled-components'

import NewsLetter from './NewsLetter'
import SocialLinks from './SocialLinks'
import FooterLinks from './FooterLinks'
import EarthGlow from './EarthGlow'

const SpaceS = styled.div`
  height: 5rem;
`;

const Component: React.FC = () => (
  <>
    <NewsLetter />

    <SpaceS />

    <SocialLinks />

    <SpaceS />

    <FooterLinks />

    <EarthGlow />
  </>
)

export default Component
