import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import Avatar from './Avatar'
import Title from './SectionTitle'
import odayImg from '../assets/persons/oday.png'
import TuuliUtrainienImg from '../assets/persons/TuuliUtrainien.png'
import carolinaLindqImg from '../assets/persons/carolinaLindq.png'
import KonstantinosSamarsTsakiImg from '../assets/persons/Konstantinos-Samaras-Tsakiris.png'
import KonstantinosPlatisImg from '../assets/persons/KonstantinosPlatis.png'
import { Sizes } from '../Config'

const Flex = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${Sizes.xs}) {
    flex-direction: column;
    align-items: center;
  }
`;

const FlexN = styled(Flex)`
  flex-wrap: nowrap;
  align-items: baseline;
`;

const Component: React.FC<{title?: string}> = ({title = 'The Team'}) => (
  <Container>
    <Title>{title}</Title>
    <Flex>
      <FlexN>
        <Avatar
          src={odayImg}
          name="Oday Darwich"
          sub1="Free particle, Innovation R&D"
          sub2="Engineer, Serial Learner"
          sub3="at CERN IdeaSquare"
        />
        <Avatar
          src={TuuliUtrainienImg}
          name="Tuuli Utrainien"
          sub1="Cosmic collaboration enabler,"
          sub2="at Cern IdeaSquare"
        />
        <Avatar
          src={carolinaLindqImg}
          name="Carolina Lindqvist"
          sub1="HPC DevOps. The Art of"
          sub2="Computer Programming at CERN"
        />
      </FlexN>
      <FlexN>
        <Avatar
          src={KonstantinosSamarsTsakiImg}
          name="Konstantinos SamarasTsakiris"
          sub1="Cloud Software Engineering at CERN"
        />
        <Avatar
          src={KonstantinosPlatisImg}
          name="Konstantinos Platis"
          sub1="Full Stack Web Developer at CERN"
        />
      </FlexN>
    </Flex>
  </Container>
)

export default Component