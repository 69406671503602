import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import { Sizes } from '../Config'

const VideoWrapper = styled.div`
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  box-shadow: 0px 0px 2.5rem .4rem rgb(0, 134, 210);
  + .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const Margin = styled.div`
  margin: 1em;
`;

const ContainerS = styled(Container)`
  // workaround Projects slider

  margin-top: 24em;

  @media (max-width: ${Sizes.xl}) {
    margin-top: 16em;
  }

  @media (max-width: ${Sizes.l}) {
    margin-top: 38em;
  }

  @media (max-width: ${Sizes.m}) {
    margin-top: 34em;
  }

  @media (max-width: ${Sizes.s}) {
    margin-top: 26em;
  }

  @media (max-width: ${Sizes.xs}) {
    margin-top: 8em;
  }
`;


const Component: React.FC = () => (
  <ContainerS>
    <Margin>
      <VideoWrapper>
        <iframe
          className="embed-responsive-item"
          src="https://www.youtube.com/embed/RCZaIGfJ5fo"
          title="First Ever Quantum Hackathon at CERN : 2019 | Quantum FutureX"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
    </Margin>
  </ContainerS>
)

export default Component