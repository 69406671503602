import React from 'react'
import styled from 'styled-components'
import { Element } from 'react-scroll'

import Header from '../components/Header'
import Intro from '../components/Intro'
import BlackPlanetOctupusText from '../components/BlackPlanetOctupusText'
import About from '../components/About'
import Video from '../components/Video'
import Images from '../components/Images'
import Space from '../components/Space'
import Coachers from '../components/Coachers'
import Featured from '../components/Featured'
import Boosters from '../components/Boosters'
import Team from '../components/Team'
import Volunteers from '../components/Volunteers'
import Projects from '../components/Projects'
import Schedule from '../components/Schedule'
import Partners from '../components/Partners'
import Supporters from '../components/Supporters'
import Footer from '../components/Footer'

import starsImg from '../assets/stars.png'

const Content = styled.div`
  background: black url(${starsImg});
  background-size: 800px;
`;


// react.suspence here lazy load all parts

export default () => (
  <Content>
    <Header />
    <Intro />

    <Space size={4} />

    <Element name='about'>
      <About/>
    </Element>

    <Space size={4} />

    <Element name='projects'>
      <Projects />
    </Element>

    <Space size={4} />

    <Video />

    <Space size={4} />

    <Images />

    <Space size={4} />

    <Element name='speakers'>
      <Coachers />
    </Element>

    <Space size={4} />

    <Featured />

    <Space size={4} />

    <Boosters />

    <Space size={4} />

    <Element name='team'>
      <Team />
    </Element>

    <Space size={4} />

    <Element name='volunteers'>
      <Volunteers />
    </Element>

    <Space size={4} />

    <Element name='schedule'>
      <Schedule />
    </Element>

    <Space size={4} />

    <Element name='partners'>
      <Partners />
    </Element>

    <Space size={4} />

    <Supporters />

    <Space size={4} />

    <Element name='footer'>
      <Footer />
    </Element>
  </Content>
)
