import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import Avatar from './Avatar'
import Title from './SectionTitle'
import TuuliUtrainienImg from '../assets/persons/TuuliUtrainien.png'
import RomaiMullerImg from '../assets/persons/RomaiMuller.png'

const Flex = styled.div`
  display: flex;

  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const A = styled(Avatar)`
  margin: 0 1em;
`;

const Component: React.FC = () => (
  <Container>
    <Title>Collaborative Booster</Title>
    <Flex>
      <A
        src={TuuliUtrainienImg}
        name="Tuuli Utrainien"
        sub1="Cosmic callaboration enabler,"
        sub2="at CERN IdeaSquare"
      />
      <A
        src={RomaiMullerImg}
        name="Romain Muller"
        sub1="EU Projects Officer, at CERN"
      />
    </Flex>
  </Container>
)

export default Component