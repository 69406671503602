import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function useInterval(callback : any, interval: any) {
  const saveCallback = useRef();

  useEffect(() => {
    saveCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      // @ts-ignore
      saveCallback.current();
    }

    let id = setInterval(tick, interval);
    return () => clearInterval(id);
  }, [interval]);
}

export { useInterval };


export const BoldFont = styled.div`
  font-weight: bold;
`

export const BolderFont = styled(BoldFont)<{color?: string}>`
  text-shadow: 1px 0 ${props => props.color || 'white'};
`;

export const SemiBoldFont = styled.div`
  font-weight: 600;
`

export const MediumFont = styled.div`
  font-weight: 500;
`

export const NormalFont = styled.div`
  font-weight: 400;
`

// @ts-ignore
export const useResize = (myRef) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions;
};
