import React from 'react'
import styled from 'styled-components'

import { BolderFont } from '../Helpers'

const Title = styled(BolderFont)`
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin: 2em 0;
`;

const Component: React.FC = ({children}) => (
  <Title>{children}</Title>
)

export default Component