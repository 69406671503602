import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import Title from './SectionTitle'
import { Colors, Sizes } from '../Config'
import { BoldFont } from '../Helpers'

const Flex = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;

  max-width: 50rem;
  margin: auto;
`;

const Row = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;

  @media (max-width: ${Sizes.m}) {
    margin: .5em 0;
  }
`;

const SubTitle = styled.p`
  color: ${Colors.pink100};
  font-weight: bold;
  align-self: center;
  font-size: 1.5em;
`;

const Range = styled.div`
  width: 9em;
  min-width: 9em;
  border-radius: 4px;
  color: white;
  background: ${Colors.pink100};
  text-align: center;
  margin: .3em 1.5em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Desc = styled.div`
`;

const Margin = styled.div`
  margin: 0 .2em;
`;

const Component: React.FC = () => (
  <Container>
    <BoldFont>
    <Margin>
      <Title>Schedule</Title>

      <Flex>
        <SubTitle>Friday 18 October 2019</SubTitle>

        <Row>
          <Range>15:00 - 16:00</Range>
          <Desc>Registraction & Check-in</Desc>
        </Row>
        <Row>      
          <Range>16:00 - 18:40</Range>
          <Desc>Discover CERN: Visits to the CMS Detector, Data Center and Antimatter Factory</Desc>
        </Row>
        <Row>
          <Range>19:00 - 20:00</Range>
          <Desc>Birth of World Wide Web by Ben Segal - The Mentor of Tim Berners-Lee at CERN</Desc>
        </Row>
        <Row>
          <Range>20:00 - 21:30</Range>
          <Desc>Dinner with optional Helium Pitching</Desc>
        </Row>
        <Row>
          <Range>21:30 - 22:30</Range>
          <Desc>Fun Activities</Desc>
        </Row>

        <SubTitle>Saturday 19 October 2019</SubTitle>

        <Row>
          <Range>8:00 - 8:50</Range>
          <Desc>Breakfast</Desc>
        </Row>
        <Row>      
          <Range>9:00 - 9:10</Range>
          <Desc>Hackathon Begin</Desc>
        </Row>
        <Row>
          <Range>9:10 - 9:40</Range>
          <Desc>Opening Ceremony</Desc>
        </Row>
        <Row>
          <Range>9:45 - 9:55</Range>
          <Desc>Coffee Break</Desc>
        </Row>
        <Row>
          <Range>10:00 - 10:55</Range>
          <Desc>Workshops: Innovation through movement</Desc>
        </Row>
        <Row>
          <Range>11:00 - 11:55</Range>
          <Desc>Workshops: Quantum Games / Convener: James Wootton (IBM)</Desc>
        </Row>
        <Row>
          <Range>12:00 - 12:55</Range>
          <Desc>Workshops: Building Worlds With Words: Generating Writing Inspiration When The Clock Is Ticking / Convener: Putney Charlen</Desc>
        </Row>
        <Row>
          <Range>13:00 - 14:55</Range>
          <Desc>Lunch</Desc>
        </Row>
        <Row>
          <Range>15:00 - 19:30</Range>
          <Desc>Hacking (coffee break included)</Desc>
        </Row>

        <SubTitle>Sunday 20 October 2019</SubTitle>

        <Row>
          <Range>8:30 - 9:10</Range>
          <Desc>Breakfast</Desc>
        </Row>
        <Row>
          <Range>9:15 - 11:45</Range>
          <Desc>Hacking (coffee break included)</Desc>
        </Row>
        <Row>
          <Range>11:45 - 12:45</Range>
          <Desc>Hackathon Ends - Lunch</Desc>
        </Row>
        <Row>
          <Range>12:45 - 13:00</Range>
          <Desc>All participants move to the Council Chamber</Desc>
        </Row>
        <Row>
          <Range>13:00 - 13:15</Range>
          <Desc>Opening session</Desc>
        </Row>
        <Row>
          <Range>13:15 - 14:25</Range>
          <Desc>Final Presentation</Desc>
        </Row>
        <Row>
          <Range>14:25 - 15:00</Range>
          <Desc>Exchange and Feedback</Desc>
        </Row>
        <Row>
          <Range>15:00 - 15:10</Range>
          <Desc>Talk: Prof. John Ellis, Clerk Maxwell Professor of Theoretical Physics at King's College in London</Desc>
        </Row>
        <Row>
          <Range>15:10 - 15:15</Range>
          <Desc>Q & A</Desc>
        </Row>
        <Row>
          <Range>15:25 - 15:35</Range>
          <Desc>Talk: Jack Hidary, Director of AI & Physics at Alphabet X (formerly Google X)</Desc>
        </Row>
        <Row>
          <Range>15:35 - 15:40</Range>
          <Desc>Q & A</Desc>
        </Row>
        <Row>
          <Range>15:50 - 16:00</Range>
          <Desc>Closing Session</Desc>
        </Row>
        <Row>
          <Range>16:00 - 17:00</Range>
          <Desc>Refreshment and Networking</Desc>
        </Row>
       </Flex>
    </Margin>
    </BoldFont>
  </Container>
)

export default Component