import React from 'react'
import styled from 'styled-components'
import Scroll from 'react-scroll'

import { Sizes, Links } from '../Config'

const Flex = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
`;

const Seperator = styled.div`
  height: 3em;
  margin: 3em;
  width: 1px;
  background: white;

  @media (max-width: ${Sizes.s}) {
    margin: 1em;
  }
`;

const Link = styled.span`
  line-height: 2em;
`;

const WhiteLink = styled.a`
  color: white;
`;

const ScrollLink = Scroll.Link

const Component: React.FC = () => (
  <Flex>
    <Column>
      <ScrollLink to="about" spy={true} smooth={true} duration={500}>
        <Link>About</Link>
      </ScrollLink>
      <ScrollLink to="partners" spy={true} smooth={true} duration={500}>
        <Link>Partners</Link>
      </ScrollLink>
      <WhiteLink href="mailto:info@quantumfuturex.ch">
        <Link>Contact Us</Link>
      </WhiteLink>
    </Column>

    <Seperator></Seperator>

    <Column>
    <ScrollLink to="team" spy={true} smooth={true} duration={500}>
      <Link>Team</Link>
    </ScrollLink>
    <ScrollLink to="" spy={true} smooth={true} duration={500}>
      <Link>Ambassadors</Link>
    </ScrollLink>
    <WhiteLink href={Links.slack}>
      <Link>Join the Slack community</Link>
    </WhiteLink>
    </Column>

    <Seperator></Seperator>

    <Column>
      <ScrollLink to="" spy={true} smooth={true} duration={500}>
        <Link>FAQ</Link>
      </ScrollLink>
    </Column>
  </Flex>
)

export default Component
