import React, { useState } from 'react';
import styled from 'styled-components'
import axios from 'axios'

import Container from './Container'
import { Colors, NewsLetterConfig } from '../Config'
import { BoldFont, SemiBoldFont } from '../Helpers'

const Input = styled.input`
  padding: .5em;
  width: 100%;
  max-width: 15rem;
  margin-top: 1em;
  border: 0;
  border-radius: 4px;
  height: 1.5em;
`;

const Button = styled.button`
  width: 10em;
  height: 2.5em;
  border: 0;
  border-radius: 4px;
  margin-top: 1em;
  background: ${Colors.pink100};
  color: white;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

const NewsLetterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const SubTitle = styled.p`
  font-size: 2em;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default () => {
  const [email, setEmail] = useState('');
  const [posted, setPosted] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);

  // @ts-ignore
  const doSubmit = async (e) => {
      e.preventDefault();
      setCanSubmit(false);

      let formData = new FormData();

      formData.append(`entry.${NewsLetterConfig.id}`, email)

      await axios({
        url: `${NewsLetterConfig.cors}${NewsLetterConfig.formUrl}`,
        method: 'post',
        data: formData,
        responseType: 'json'
      })
      .then((response) => {
        setPosted(true);
        console.log('response', response);
      })
      .catch(err => {
        setPosted(true);
        console.log('err', err);
      });

      setCanSubmit(true);
    }

  return (
    <Container>
      <NewsLetterContainer>
        <SemiBoldFont><SubTitle>NewsLetter</SubTitle></SemiBoldFont>
        {!posted && <>
          <div>
          To receive our updates <br />
          please subscribe to our <br />
          newsletter
          </div>

          <Form onSubmit={doSubmit}>
            <Input type='email' placeholder='ex: john@example.com' required value={email} onChange={e => setEmail(e.target.value)} />

            <Button disabled={!canSubmit}>Subscribe</Button>
          </Form>
        </>}
        {posted &&
          <BoldFont>We will keep you posted.</BoldFont>
        }
      </NewsLetterContainer>
    </Container>
  )
}
