import React from 'react'
import styled from 'styled-components'

import SwiftSlider from './SwiftSlider'
import Container from './Container'
import { Colors, Sizes, Links } from '../Config'
import Image1Url from '../assets/images/1.jpg'
import Image2Url from '../assets/images/2.jpg'
import Image3Url from '../assets/images/3.jpg'
import Image4Url from '../assets/images/4.jpg'
import Image5Url from '../assets/images/5.jpg'
import Image6Url from '../assets/images/6.jpg'
import CameraImg from '../assets/icons/camera.png'
import { MediumFont } from '../Helpers'

const QFHack = styled.div`
  margin: auto;
  color: white;
  background: ${Colors.pink100};
  border-radius: 4px;
  width: fit-content;
  text-align: center;
  padding: 0 .5rem;

`;

const Center = styled.a`
  display: flex;

  flex-direction: column;

  align-items: center;

  margin-top: 13em;
  color: white;

  // from https://github.com/zulucoda/react-swift-slider/blob/master/src/react-swift-slider/components/Slider.js
  @media (max-width: ${Sizes.m}) {
    margin-top: 17em;
  }

  @media (max-width: 600px) {
    margin-top: 3em;
  }
`;

const Icon = styled.img`
  margin-top: 2rem;
  margin-bottom: .3rem;
  width: 2rem;
`;

const Margin = styled.div`
  margin: 1em;
`;

const Component: React.FC = () => (
  <Container>
    <MediumFont>
      <Margin>
        <SwiftSlider
          dotColor={'white'}
          activeDotColor={Colors.pink100}
          interval={3000}
          height={600}
          enableNextAndPrev={false}
          data={[
            {
              'id': 1,
              'src': Image1Url,
            },
            {
              'id': 2,
              'src': Image2Url,
            },
            {
              'id': 3,
              'src': Image3Url,
            },
            {
              'id': 4,
              'src': Image4Url,
            },
            {
              'id': 5,
              'src': Image5Url,
            },
            {
              'id': 6,
              'src': Image6Url,
            },
          ]}
        />

        <Center href={Links.photos}>
          <QFHack>Check photos of 2019 on Flicker</QFHack>

          <Icon src={CameraImg} />

          <div>Photos of 2019</div>
        </Center>
      </Margin>
    </MediumFont>
  </Container>
)

export default Component
