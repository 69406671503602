import React from 'react';

import './App.css';
import IndexPage from './pages/index';


function App() {
  return (
    <IndexPage />
  );
}

export default App;
