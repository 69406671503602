export const Colors = {
  pink100: '#de0680',
}

export const SizesI = {
  xs: 375,
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const Sizes = {
  xs: SizesI.xs + 'px',
  s: SizesI.s + 'px',
  m: SizesI.m + 'px',
  l: SizesI.l + 'px',
  xl: SizesI.xl + 'px',
}

export const Links = {
  facebook: 'https://www.facebook.com/events/2583198141711887/',
  twitter: 'https://twitter.com/quantumfuturex',
  instagram: 'https://instagram.com/quantumfuturex?igshid=1mwe4ujy507fg',
  linkedin: 'https://www.linkedin.com/in/quantum-futures/',
  slack: 'https://join.slack.com/t/quantumfutures/shared_invite/enQtOTYxNjI4MDE0NTQ0LWY1NTQ2OGQxODJkMzAxMzNhNGEyYTlhZTM3YzE2NDBjMjc0NzA1ZjFlNmI2NDA2ZjhiOTg3NWFhNzI3MTE1N2E',
  github: 'https://google.com/',
  reddit: 'https://reddit.com/',
  youtube: 'https://www.youtube.com/channel/UCbg79v5fnfmZgDHcs8Cv8iw',
  flickr: 'https://www.flickr.com/people/quantumfuturex/',
  photos: 'https://www.flickr.com/photos/quantumfuturex/',
}

export const NewsLetterConfig = {
  cors: 'https://cors-anywhere.herokuapp.com/',
  formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSfuTsypIHBPfUyKAiZAIQ12vLVK5m24AJePQYdXXlnmuHe_mw/formResponse',
  id: 2080170423,
}
